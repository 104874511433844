<script lang="ts">
  export let text: string
  export let className = ''
  export let textColor = 'black'
</script>

<p class={className} style="--color: {textColor};">{text}</p>

<style>
  p {
    @apply text-base
      inline-block;
    color: var(--color);
  }
  @screen desktop {
    p {
      @apply text-xl;
    }
  }
  @screen laptop {
    p {
      @apply text-xl;
    }
  }
</style>
