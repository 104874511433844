<script lang="ts">
  import clsx from 'clsx'
  export let text: string
  export let className = ''
  export let textColor = 'black'
  export let decoration = false
</script>

<p
  class={clsx(className, decoration && 'decoration')}
  style="

--color: {textColor};"
>
  {text}
</p>

<style>
  .decoration {
    display: inline;
    background: linear-gradient(transparent 50%, #a19760 50%);
  }

  p {
    @apply text-xl inline-block;

    color: var(--color);
  }

  @screen laptop {
    p {
      @apply text-2xl;
    }
  }

  @screen desktop {
    p {
      @apply text-3xl;
    }
  }
</style>
