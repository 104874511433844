<script>
  import clsx from 'clsx'
  import BodyLarge from 'src/theme/text/BodyLarge.svelte'

  import TitleSmall from 'src/theme/text/TitleSmall.svelte'

  export let caption
  export let highlight
  export let imageSrc
  export let className = ''
</script>

<div class={clsx('card', className)}>
  <img class="img" src={imageSrc} alt="user_image" />
  <div class="description">
    <div class="sub-text">
      <div><BodyLarge text={caption} textColor="white" /></div>
    </div>
    <div class="highlight">
      <TitleSmall text={highlight} className="font-bold" textColor="white" decoration />
    </div>
  </div>
</div>

<style lang="postcss">
  .card {
    @apply flex;

    width: 90%;
  }

  .description {
    @apply flex flex-col justify-center;
  }

  .sub-text {
    @apply -rotate-12 
      mb-6;
  }

  .img {
    @apply mr-5;

    width: 130px;
    height: 130px;
  }

  @screen laptop {
    .img {
      width: 250px;
      height: 250px;
    }

    .sub-text {
      @apply mb-12;
    }
  }

  @screen desktop {
    .card {
      width: 43%;
    }

    .img {
      width: 250px;
      height: 250px;
    }

    .sub-text {
      @apply mb-12;
    }
  }
</style>
