<script lang="ts">
  import RoundButton from 'src/elements/RoundButton.svelte'
  import Body from 'src/theme/text/Body.svelte'
  import TitleSmall from 'src/theme/text/TitleSmall.svelte'
  import PlanCard from './PlanCard.svelte'

  export let coachId = ''
  export let onClickApplyTrial = (coachId: string) => {
    console.log('onClickApplyTrial', coachId)
  }
</script>

<div class="container">
  <div class="profile-container">
    <img class="coach-img" src="/images/coach-2.jpeg" alt="coach" />
    <div class="profile">
      <TitleSmall text="下地 眞琴" className="font-bold" />
      <Body
        text="システムエンジニア歴 7年目です。現在は、ユーザー系のSierでKintoneを扱ったアプリ開発を担うチームのリーダーをしています。大学では機械工学というITとはあまり関係のない学科を専攻していましたが、IT会社に就職しました。その為、IT未経験から転職する方などの気持ちは理解できると思います。また、派遣社員の面談なども行っていますので、面接の際に重要なポイントなどもお話できます。"
        className="mt-6"
      />

      <Body text="■ コーチングに関して" className="mt-4" />
      <Body
        text="新人指導・育成を行う機会も多くありますが、ティーチング(指導)だけだと限界があることを実感し、コーチングを学び取り入れるようになりました。主体的に行動を促すことが人の能力を引き上げ、自分の目的を達成するのに繋がると確信しています。コーチングにより、あなたの状況や感情に寄り添い、一緒に目標に向かい全力で伴走致します。"
      />

      <Body text="■ 主なスキルセット" className="mt-4" />
      <Body text="✅ Kintoneアプリ開発" />
      <Body text="✅ Webアプリ開発(Vue.js,node.js,MySQL)" />
      <Body text="✅ オンプレミスサーバー保守・運用" />
      <Body text="✅ アジャイル開発" />
      <Body text="✅ Webページ制作（html,css,js)" />
      <Body text="✅ システム運用保守（Windows Server , Red hat)" />
      <Body text="✅ Kintoneカスタマイズ講座（社内向け）" />

      <Body text="■ こんな方におすすめ" className="mt-4" />
      <Body text="・ IT未経験で転職するのに不安がある" />
      <Body text="・ どのように学習したらいいかわからない" />
      <Body text="・ 学習が中途半端になり、続かない" />

      <Body text="■ コーチングセッション対応可能時間" className="mt-4" />
      <Body text="平日：20:00〜23:00" />
      <Body text="土日祝：7:30〜12:00 13:00〜23:00" />
      <Body text="※ 詳細な時間は、無料相談にて調整可能です。" />
    </div>
  </div>
  <div class="plan-container">
    <div class="plan-card"><PlanCard plan={{ name: '2回/月コース', price: '8,000円' }} /></div>
    <div class="plan-card"><PlanCard plan={{ name: 'お試し(1回)', price: '4,000円' }} /></div>
  </div>
  <RoundButton text="無料相談する" className="mt-16" on:click={() => onClickApplyTrial(coachId)} minWidth={200} />
</div>

<style>
  .container {
    @apply flex 
      flex-col pb-8;
  }

  .profile-container {
    @apply flex 
      w-full
      flex-col;
  }

  .coach-img {
    @apply w-28
      h-28
      mb-16;

    border-radius: 50%;
  }

  .plan-container {
    @apply mt-8
    flex 
      justify-center
      items-center
      flex-wrap
      gap-4;
  }

  .plan-card {
    @apply w-full;
  }

  @screen tablet {
    .plan-card {
      @apply w-full;
    }
  }

  @screen laptop {
    .coach-img {
      @apply w-40 
        h-40;
    }

    .plan-container {
      @apply justify-center;
    }

    .plan-card {
      @apply w-48;
    }
  }

  @screen desktop {
    .coach-img {
      @apply mr-16;
    }

    .plan-container {
      @apply justify-center gap-32;
    }

    .plan-card {
      @apply w-60;
    }
  }
</style>
