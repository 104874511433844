<script>
  import clsx from 'clsx'
  import SubHead from 'src/theme/text/SubHead.svelte'

  export let className = ''
</script>

<div class={clsx('card', className)}>
  <SubHead className="inline" text="パーソナルコーチングは" />
  <SubHead className="inline" text="プログラミングスクールや学習コンテンツではない" decoration />
  <SubHead className="inline" text="からこそ、" />
  <SubHead className="inline" text="様々なニーズを解消することができます。" decoration />
</div>

<style lang="postcss">
  .card {
    @apply w-full 
      py-4 
      px-8
      bg-white
      z-10;
  }

  @screen laptop {
    .card {
      @apply py-8 px-16;
    }
  }

  @screen desktop {
    .card {
      @apply py-16 px-32;
    }
  }
</style>
