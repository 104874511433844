<script lang="ts">
  import RoundButton from 'src/elements/RoundButton.svelte'

  import Title from 'src/theme/text/Title.svelte'
</script>

<div class="section">
  <div class="inner">
    <div class="description">
      <div>
        <Title text="パーソナルコーチと一緒に未来への一歩を踏み出してみませんか？" className="font-bold text-center" />
      </div>
      <div class="mt-4 text-center"><Title text="まずは気軽に無料相談へ！" className="font-bold text-center" /></div>
    </div>

    <div class="button">
      <RoundButton text="無料相談を受けてみる" on:click />
    </div>

    <div class="image">
      <img src="/images/members.svg" alt="members" />
    </div>
  </div>
</div>

<style lang="postcss">
  .section {
    @apply relative 
      py-16;
  }

  .inner {
    @apply relative
      container
      h-full
      flex
      flex-col
      items-center
      mx-auto
      px-5;
  }

  .description {
    @apply flex flex-col justify-center px-6;
  }

  .button {
    @apply mt-16;
  }

  .image {
    @apply h-52;
  }

  .image img {
    @apply h-full;
  }

  @screen laptop {
    .image {
      @apply h-[400px];
    }
  }

  @screen desktop {
    .image {
      @apply h-[500px];
    }
  }
</style>
