<script>
</script>

<div class="card">
  <div class="bg clip" />
  <p>完全<br />個別</p>
  <svg class="ic-close" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
    />
  </svg>
  <p class="text-1to1">オンライン<br />対応</p>
</div>

<style lang="postcss">
  .card {
    @apply relative
      px-4
      py-4 
      bg-white
      flex
      items-center
      mx-4;

    filter: drop-shadow(0 4px 4px rgb(0 0 0 / 25%));
  }

  .bg {
    @apply absolute
      left-0
      h-full
      bg-appYellow;

    width: 48%;
  }

  .clip {
    clip-path: polygon(0 0, 60% 0, 100% 100%, 0% 100%);
  }

  p {
    @apply text-xl
      font-bold
      tracking-widest
      z-10;
  }

  .ic-close {
    @apply ml-3 mr-auto z-10;

    width: 30px;
    height: 30px;
    fill: white;
  }

  .text-1to1 {
    @apply text-center;
  }

  @screen laptop {
    .card {
      @apply px-8 
        py-8;
    }

    p {
      @apply text-5xl;

      line-height: 1.2;
    }

    .ic-close {
      @apply mr-16;

      width: 60px;
      height: 60px;
    }
  }

  @screen desktop {
    .card {
      @apply px-8 
        py-8;
    }

    p {
      @apply text-5xl;
    }

    .ic-close {
      @apply mr-16;

      width: 60px;
      height: 60px;
    }
  }
</style>
