<script>
</script>

<div class="wrapper">
  <img src="/images/coach_w.png" alt="concept_image" />
</div>

<style lang="postcss">
  .wrapper {
    @apply z-10;
  }

  img {
    width: 150px;
  }

  @screen laptop {
    .wrapper {
      @apply hidden;
    }
  }

  @screen desktop {
    .wrapper {
      @apply hidden;
    }
  }
</style>
