<script lang="ts">
  import clsx from 'clsx'
  import Body from 'src/theme/text/Body.svelte'
  import PointTitleText from './PointTitleText.svelte'

  export let iconSrc: string
  export let stepSrc: string
</script>

<div class={clsx('card', $$props.class)}>
  <div class="bg-head clip-circle">
    <div class="bg-head-inner" />
  </div>
  <div class="bg-body" />
  <div class="inner">
    <img src={iconSrc} alt="point_image" class="image" />
    <img src={stepSrc} alt="point" class="h-6" />
    <div class="title"><PointTitleText text={$$props.title} /></div>
    <div class="description">
      <Body text={$$props.description} />
    </div>
  </div>
</div>

<style lang="postcss">
  .card {
    @apply relative
      flex 
      p-6;
    width: 100%;
  }
  .bg-head {
    @apply absolute
      top-0 
      left-0
      bg-white;
    width: 100%;
    height: 120px;
  }
  .bg-body {
    @apply absolute
      bg-white;
    top: 120px;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .clip-circle {
    clip-path: ellipse(50% 100% at 50% 100%);
  }
  .inner {
    @apply z-10
      flex
      flex-col 
      justify-start;
  }
  .image {
    @apply h-40;
  }
  .title {
    @apply self-center mt-3;
  }
  .description {
    @apply mt-6;
  }
</style>
