<script>
  import ProblemCard from './ProblemCard.svelte'
  import SolutionCard from './SolutionCard.svelte'
</script>

<div class="section">
  <div class="inner">
    <div class="problem"><ProblemCard /></div>
    <img class="arrow" src="/images/arrow-down.svg" alt="arrow" />
    <div class="solution z-10"><SolutionCard /></div>
  </div>
</div>

<style lang="postcss">
  .section {
    @apply relative;
  }

  .inner {
    @apply relative
      h-full
      flex
      flex-col
      items-center
      container 
      mx-auto
      px-5;
  }

  .problem {
    @apply mt-24;
  }

  .arrow {
    @apply mt-4;
  }

  .solution {
    @apply mt-4;
  }

  @screen laptop {
    .inner {
      @apply px-16;
    }

    .problem {
      @apply mt-24 
        px-14;
    }

    .arrow {
      @apply mt-8;
    }

    .solution {
      @apply mt-8;
    }
  }

  @screen desktop {
    .inner {
      @apply px-0;
    }

    .problem {
      @apply mt-32;
    }

    .arrow {
      @apply mt-16;
    }

    .solution {
      @apply mt-16 mx-12;
    }
  }
</style>
