<script>
  import Body from 'src/theme/text/Body.svelte'
  import TitleSmall from 'src/theme/text/TitleSmall.svelte'

  // プランのデータをpropsで受け取る
  export let plan = {
    name: '4回/月コース',
    price: '16,000円',
  }
</script>

<div class="plan-card">
  <div class="plan-name">
    <Body text={plan.name} className="font-bold text-white" />
  </div>
  <TitleSmall text={plan.price} className="font-bold" />
</div>

<style>
  .plan-card {
    @apply py-4
      px-5
      bg-appBlue-500
      shadow-md
      text-center
      rounded-lg;
  }

  .plan-name {
    @apply px-5 py-2
      bg-[#00e3c3]
      rounded-2xl
      mb-6;
  }
</style>
