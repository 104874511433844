<script>
  import ConceptImageTablet from './ConceptImageTablet.svelte'
</script>

<div class="wrapper">
  <div class="bg-mask" />
  <ConceptImageTablet />
</div>

<style lang="postcss">
  .wrapper {
    @apply absolute
      top-0
      left-0
      -z-10      
      h-full
      w-full
      p-5
      hidden;
  }
  .bg-mask {
    @apply h-full
      w-full
      bg-lpMask;
  }
  @screen laptop {
    .wrapper {
      @apply block;
    }
  }
  @screen desktop {
    .wrapper {
      @apply hidden;
    }
  }
</style>
