<script>
  import TitleDecoration from 'src/components/lp/TitleDecoration.svelte'
  import Body from 'src/theme/text/Body.svelte'
  import Title from 'src/theme/text/Title.svelte'
  import BenefitsCard from './BenefitsCard.svelte'
</script>

<div class="section">
  <div class="inner">
    <div class="title">
      <TitleDecoration color="black">
        <Title text="パーソナルコーチングで得られるメリット" className="font-bold" />
      </TitleDecoration>
    </div>
    <div class="cards">
      <div>
        <BenefitsCard no="01" title="ひとりで自走する力を身につけられる" imgSrc="/images/benefit-1.png">
          <Body
            text="ソフトウェア開発で起こる問題は、状況により様々です。解決策が一つとは限りません。"
            className="mt-8"
          />
          <Body
            text="問題の正解を教えるだけではなく、『エンジニアとしての考え方』や『問題解決のプロセス』を学習することで、ひとりで成長していくための基礎力を身につけられます。"
          />
        </BenefitsCard>
      </div>
      <div>
        <BenefitsCard no="02" title="目標達成へ確実に前進できる" imgSrc="/images/benefit-2.png" reverse>
          <Body
            text="専門性の高いソフトウェアの世界で、ひとりで目標へ向かって努力することは大変です。"
            className="mt-8"
          />
          <Body
            text="コーチと一緒に目標設定やふりかえりをおこなうことで、安心して目標へ向かって努力することができます。"
          />
        </BenefitsCard>
      </div>
      <div>
        <BenefitsCard no="03" title="専門的な相談ができる" imgSrc="/images/benefit-3.png">
          <Body
            text="一般的なコーチでは回答が難しい専門的な相談も、エンジニアに向けたコーチングサービスなら、適切な回答が得られます。"
            className="mt-8"
          />
          <Body text="現役のエンジニアコーチには、技術的な悩みも相談することができます。" />
        </BenefitsCard>
      </div>
    </div>
  </div>
</div>

<style>
  .section {
    @apply relative 
      pt-16;
  }

  .inner {
    @apply relative
      h-full
      flex
      flex-col
      items-center
      container 
      mx-auto
      px-5
      py-8;
  }

  .cards {
    @apply mt-16;
  }

  @screen laptop {
    .cards {
      @apply mt-16 space-y-6;
    }
  }

  @screen desktop {
    .cards {
      @apply mt-16 space-y-12;
    }
  }
</style>
