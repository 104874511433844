<script lang="ts">
  import TitleDecoration from 'src/components/lp/TitleDecoration.svelte'
  import Title from 'src/theme/text/Title.svelte'
  import QACard from './QACard.svelte'
  import type { Qa } from './types'

  const qaList: Qa[] = [
    {
      question: 'プログラミング経験が少なくても大丈夫ですか？',
      answer:
        '大丈夫です。プログラミング経験に関わらず、目指すキャリアや目標に合わせてサポートいたします。まずは気軽にご相談ください。',
    },
    {
      question: 'プログラミングの教材やカリキュラムは提供されていますか？',
      answer:
        '一般的なプログラミングスクールのように、教材やカリキュラムの提供は行っておりません。自身でやりたい勉強方法を選択していただく形になっております。ただしコーチが学習内容やカリキュラムの方針についてアドバイスや提案を行うため、何を学習するか迷っている方でも安心して学習を進められます。',
    },
    {
      question: '過去どんな人がサービスを利用していますか？',
      answer:
        'フリーランスのエンジニアや、一般のIT企業に勤めるエンジニア、これからエンジニアとして活動したい方など、様々な方がサービスを利用しています。',
    },
    {
      question: '定期面談は１回どのくらいの時間ですか？',
      answer:
        '1回で約1時間を目安に面談を行います。面談の間隔を長く希望される方は、少し長めにすることも可能です。逆に30分程度に短縮することも可能です。',
    },

    {
      question: '定期面談はどのようなツールによって行いますか？',
      answer:
        '環境に特に問題がない場合、Google Meetを使って面談を行います。ご希望であれば別のツールでも対応は可能です。',
    },
    {
      question: '決済はどのような方法で行いますか？',
      answer:
        '月に1回、クレジットカードで決済いただきます。決済日付は契約日付からの計算となるため、月の途中で解約したとしても次の契約日まではサービスはご利用可能です。',
    },
    {
      question: '入会金や退会料はかかりますか？',
      answer:
        '入会金や退会料は一切かかりません。月単位の契約なので1ヶ月で解約しても1ヶ月以上の料金は一切かかりません。※ 日割り計算による決済は行っていないため、月単位での決済になる点はご了承ください。',
    },
  ]
</script>

<div id="qa" class="section">
  <div class="inner">
    <div class="title">
      <TitleDecoration color="black">
        <Title text="よくある質問" className="font-bold" />
      </TitleDecoration>
    </div>
    <div class="qa">
      {#each qaList as qa}
        <div><QACard question={qa.question} answer={qa.answer} /></div>
      {/each}
    </div>
  </div>
</div>

<style lang="postcss">
  .section {
    @apply relative 
      py-16
      bg-appBlue-500;
  }

  .inner {
    @apply relative
      container
      h-full
      flex
      flex-col
      items-center
      mx-auto
      px-5;
  }

  .qa {
    @apply w-full
      mt-16
      space-y-3;
  }
</style>
