<div class="wrapper">
  <div class="bg" />
  <div class="arrow" />
  <div class="arrow2" />
</div>

<style lang="postcss">
  .wrapper {
    @apply relative w-full
      h-20;
  }
  .bg {
    @apply absolute bg-appYellow-500;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .arrow {
    @apply absolute bg-white;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    clip-path: polygon(110% 0, -10% 0%, 50% 100%);
    content: '';
  }
  .arrow2 {
    @apply absolute z-10;
    clip-path: polygon(100% 0%, 0% 0%, 50% 80%);
    content: '';
    background-color: #404855;
    @apply w-full h-full;
  }
</style>
