<script lang="ts">
  import Router from 'svelte-spa-router'
  import routes from './routes'
</script>

<main>
  <Router {routes} />
</main>

<style global lang="postcss">
  main {
    @apply h-screen;
  }

  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @layer utilities {
    .img-cover {
      @apply absolute
        -z-10
        w-full
        h-full
        top-0
        left-0;

      object-fit: cover;
    }
  }

  :root {
    --header-height: 80px;
    --footer-height: 80px;
    --color-app-yellow: #f7dc69;
  }
</style>
