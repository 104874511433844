<script>
  import TitleDecoration from 'src/components/lp/TitleDecoration.svelte'
  import Body from 'src/theme/text/Body.svelte'
  import Title from 'src/theme/text/Title.svelte'
  import PriceCard from './PriceCard.svelte'
</script>

<div id="price" class="section">
  <div class="inner">
    <div class="title">
      <TitleDecoration>
        <Title text="料金プラン" className="font-bold text-white" />
      </TitleDecoration>
    </div>
    <div class="price mt-12">
      <PriceCard price="16,000円" session="4" />
    </div>
    <div class="line" />
    <div class="price">
      <PriceCard price="10,000円" session="2" />
    </div>
    <div class="line" />

    <div class="caption">
      <Body text="月額の利用料金のみで全てのサービスがご利用いただけます。" className="text-white" />
      <Body
        text="※金額はコーチによって異なります。詳しくはコーチの詳細ページをご確認ください。(上記は最も高い金額)"
        className="text-appYellow font-bold mt-4"
      />
      <Body text="※入会金や退会手数料などは一切かかりません。" className="text-white mt-1" />
      <Body text="※単発のご相談も5,000円/回で対応可能です(その旨ご連絡ください)。" className="text-white mt-1" />
    </div>
  </div>
</div>

<style lang="postcss">
  .section {
    @apply relative 
      pt-16;

    background-color: #404855;
  }

  .inner {
    @apply relative
      h-full
      flex
      flex-col
      items-center
      mx-auto;
  }

  .price {
    @apply w-full
      bg-white 
      bg-opacity-30
      py-8
      
      text-center;
  }

  .line {
    @apply w-full h-4 bg-appYellow;
  }

  .caption {
    @apply flex flex-col items-start mt-8 px-4;
  }
</style>
