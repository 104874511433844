<script>
  import RoundButton from 'src/elements/RoundButton.svelte'
  import Display from 'src/theme/text/Display.svelte'
  import SubHead from 'src/theme/text/SubHead.svelte'
  import Background from './Background.svelte'
  import ConceptImageSP from './ConceptImageSP.svelte'
  import IndividualCard from './IndividualCard.svelte'
  import Headline from './text/Headline.svelte'
</script>

<div class="section">
  <Background />
  <div class="inner">
    <div class="subhead">
      <Headline text="エンジニアを成功を導く" />
    </div>
    <div class="headline">
      <div class="headline-1">
        <div>
          <Display text="あなただけの" decoration={true} />
        </div>
      </div>
      <div class="headline-2">
        <div>
          <Display text="専任コーチ" decoration={true} />
        </div>
      </div>
    </div>
    <div class="text-center space-y-8 mb-3">
      <IndividualCard />
      <div class="text-individual">
        <SubHead text="エンジニアの目標達成をサポートします" decoration={true} />
      </div>
    </div>

    <div class="cta">
      <RoundButton text="今すぐ無料相談を受けてみる" on:click />
    </div>

    <div class="sp-image">
      <ConceptImageSP />
    </div>
  </div>
</div>

<style lang="postcss">
  .section {
    @apply relative;

    height: 960px;
  }

  .inner {
    @apply flex
      flex-col
      items-center
      container 
      mx-auto
      px-5;

    padding-top: 120px;
  }

  .subhead {
    @apply mb-3 
      relative;
  }

  .subhead::before {
    @apply absolute 
      border-b-2 
      border-black
      rotate-45;

    position: absolute;
    bottom: 12px;
    left: -40px;
    width: 20px;
    height: 20px;
    content: ' ';
  }

  .subhead::after {
    @apply absolute 
      border-b-2 
      border-black
      -rotate-45;

    position: absolute;
    right: -40px;
    bottom: 12px;
    width: 20px;
    height: 20px;
    content: ' ';
  }

  .headline {
    @apply mb-6;
  }

  .headline-1 {
    @apply mb-1;
  }

  .headline-2 {
    @apply ml-7;
  }

  .text-individual {
    @apply mx-12;
  }

  .cta {
    @apply flex
      justify-center
      w-full
      mt-8;
  }

  .sp-image {
    @apply mt-4;
  }

  @screen laptop {
    .section {
      height: 1140px;
    }

    .headline-1 {
      @apply mb-4;

      margin-left: -10rem;
    }

    .headline-2 {
      margin-left: -1rem;
    }

    .inner {
      padding-top: 160px;
    }

    .subhead {
      @apply mb-8 
        mr-48;
    }

    .subhead::before {
      width: 40px;
      height: 40px;
    }

    .subhead::after {
      width: 40px;
      height: 40px;
    }

    .text-individual {
      @apply mx-0;
    }

    .cta {
      @apply mt-12;
    }
  }

  @screen desktop {
    .section {
      height: 1020px;
    }

    .headline-1 {
      @apply mb-4 ml-0;
    }

    .headline-2 {
      @apply ml-40;
    }

    .inner {
      @apply items-start;

      padding-top: 120px;
    }

    .subhead {
      @apply mb-11 
        ml-60
        mr-0;
    }

    .subhead::before {
      width: 40px;
      height: 40px;
    }

    .subhead::after {
      width: 40px;
      height: 40px;
    }

    .headline {
      @apply mb-16;
    }

    .text-individual {
      @apply mx-0;
    }

    .cta {
      @apply mt-12;
    }
  }
</style>
