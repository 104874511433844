<script lang="ts">
  export let color = 'white'
</script>

<div class="content" style="--color: {color};">
  <slot>
    <span class="missing">Unknown description</span>
  </slot>
  <div class="line-l" />
  <div class="tri" />
  <div class="line-r" />
</div>

<style>
  .content {
    @apply relative pb-8;
  }
  .line-l {
    @apply absolute;
    bottom: 0;
    left: 0;
    width: calc(50% - 15.5px);
    height: 2px;
    background: var(--color);
  }
  .tri {
    @apply absolute rotate-45 mx-auto;
    right: 0;
    bottom: -11.5px;
    left: 0;
    width: 24px;
    height: 24px;
    border-right: 2px solid var(--color);
    border-bottom: 2px solid var(--color);
  }
  .line-r {
    @apply absolute;
    right: 0;
    bottom: 0;
    width: calc(50% - 26px);
    height: 2px;
    background: var(--color);
  }
</style>
