<script lang="ts">
  import RoundButton from 'src/elements/RoundButton.svelte'
  import Body from 'src/theme/text/Body.svelte'
  import TitleSmall from 'src/theme/text/TitleSmall.svelte'
  import PlanCard from './PlanCard.svelte'

  export let coachId = ''
  export let onClickApplyTrial = (coachId: string) => {
    console.log('onClickApplyTrial', coachId)
  }
</script>

<div class="container">
  <div class="profile-container">
    <img class="coach-img" src="/images/coach-5.jpeg" alt="coach" />
    <div class="profile">
      <TitleSmall text="藤原 隆幸" className="font-bold" />
      <Body
        text="東京在住のITエンジニアです。これまで25年以上にわたり、さまざまなIT分野に携わってきました。現在はクラウド技術を中心に活動しています。"
        className="mt-6"
      />
      <Body
        text="コーチを始めたきっかけは、エンジニアとして成長を目指す方々を支援し、IT業界で挑戦する楽しさを伝えたいという思いからです。そのため、エンジニアの成長を全力でサポートするITコーチングを提供したいと考えています。"
      />
      <Body
        text="長いキャリアの中で、さまざまな挑戦や困難を経験してきましたが、その中で得られた成長の喜びや達成感は何事にも代えがたいものでした。この経験を活かし、次のステージに進むお手伝いをさせていただきたいと思います。一緒に新たな目標を見つけ、それを達成するための具体的なアクションを考え、実行することで、確かな成長を感じていただけるよう全力でサポートいたします。"
      />
      <Body text="どうぞよろしくお願いいたします。" />

      <Body text="■ コーチングに関して" className="mt-4" />
      <Body
        text="新人指導・育成を行う機会も多くありますが、ティーチング(指導)だけだと限界があることを実感し、コーチングを学び取り入れるようになりました。主体的に行動を促すことが人の能力を引き上げ、自分の目的を達成するのに繋がると確信しています。コーチングにより、あなたの状況や感情に寄り添い、一緒に目標に向かい全力で伴走致します。"
      />

      <Body text="■ 主なスキルセット" className="mt-4" />
      <Body
        text="現在、クラウドサポートエンジニア/インフラスペシャリストとして活躍中。これまでの経歴として、商社、情報処理会社を経てシステムインテグレーター会社に所属。その間、ITエンジニアの基礎を習得し、法律事務所、金融、商社を主要顧客としてSLAに基づいたITソリューションの導入・構築・運用に従事してきました。"
      />
      <Body
        text="✅ 技術サポート: クラウド (特にIAAS)の製品・サービスに関する顧客の技術サポート問題の解決と推奨事項の提供"
        className="mt-4"
      />
      <Body
        text="✅ ITインフラ管理: サーバーやクライアントシステムの一連のサポート業務、包括的な提案から設計、構築、導入、運用、保守"
      />
      <Body
        text="✅ コミュニケーション: 顧客との良好なコミュニケーションにより、ソリューションの提案・コンサルティング。プロジェクトの成功に向けて、プロジェクトマネージャー・メンバーとの円滑なコミュニケーションを維持"
      />
      <Body text="✅ リスク管理: プロアクティブにログチェックやデータバックアップを行う" />
      <Body text="✅ テレワーク環境の構築・運用: COVID19をきっかけにテレワーク環境の提案、構築、運用、保守" />
      <Body text="✅ チームリーダー: 既存のチームの枠組みを超えてプロジェクトをリード" />

      <Body text="■ こんな方におすすめ" className="mt-4" />
      <Body text="▷ IT業界に初めて挑戦したい方: 業界初心者でも安心して学べる、基礎から応用までをコーチングします。" />
      <Body
        text="▷ 初級から中級エンジニアでキャリアアップを目指す方: クラウド技術やリーダーシップを強化したい方のサポートをします。"
      />
      <Body
        text="▷ IT未経験からキャリアチェンジを考えている方: 未経験者が直面する課題に寄り添い、スムーズな転職できるよう伴走します。"
      />
      <Body
        text="▷ クラウド技術やインフラ管理、社内IT運用等について話をしたい方: 実務経験に基づくアドバイスで、現場に活かせる知識を共有しエールを送りたいです。"
      />

      <Body text="■ コーチングセッション対応可能時間" className="mt-4" />
      <Body text="火曜日、水曜日、木曜日、金曜日 : 09:30 - 10:00" />
      <Body text="土曜日、日曜日 : 09:00 - 21:00" />
      <Body text="※ 詳細な時間は、無料相談にて調整可能です。" />
    </div>
  </div>
  <div class="plan-container">
    <div class="plan-card"><PlanCard plan={{ name: '2回/月コース', price: '4,000円' }} /></div>
    <div class="plan-card"><PlanCard plan={{ name: '1回/月', price: '2,000円' }} /></div>
  </div>
  <RoundButton text="無料相談する" className="mt-16" on:click={() => onClickApplyTrial(coachId)} minWidth={200} />
</div>

<style>
  .container {
    @apply flex 
      flex-col pb-8;
  }

  .profile-container {
    @apply flex 
      w-full
      flex-col;
  }

  .coach-img {
    @apply w-28
      h-28
      mb-16;

    border-radius: 50%;
  }

  .plan-container {
    @apply mt-8
    flex 
      justify-center
      items-center
      flex-wrap
      gap-4;
  }

  .plan-card {
    @apply w-full;
  }

  @screen tablet {
    .plan-card {
      @apply w-full;
    }
  }

  @screen laptop {
    .coach-img {
      @apply w-40 
        h-40;
    }

    .plan-container {
      @apply justify-center;
    }

    .plan-card {
      @apply w-48;
    }
  }

  @screen desktop {
    .coach-img {
      @apply mr-16;
    }

    .plan-container {
      @apply justify-center gap-32;
    }

    .plan-card {
      @apply w-60;
    }
  }
</style>
