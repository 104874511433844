<script lang="ts">
  import { clickEvent } from 'src/components/analytics/Analytics.svelte'
  import Logo from 'src/components/lp/Logo.svelte'
  import HeaderNavPC from 'src/components/lp/header/HeaderNavPC.svelte'
  import HeaderNavSP from './HeaderNavSP.svelte'

  let openNavMenu = false
  function scrollToSection({ target }: any) {
    const href = target.getAttribute('href')
    const el = document.querySelector(href)
    if (!el) return
    el.scrollIntoView({
      behavior: 'smooth',
    })
    clickEvent({ content_type: `nav_menu_${href.replace('#', '')}` })
    onClickCloseMenu()
  }
  function onClickMenu() {
    openNavMenu = true
  }
  function onClickCloseMenu() {
    openNavMenu = false
  }
</script>

<header>
  <div class="inner">
    <div class="logo">
      <Logo imgClass="h-9" />
    </div>
    <HeaderNavPC on:click={scrollToSection} />
    <HeaderNavSP open={openNavMenu} onClickClose={onClickCloseMenu} on:click={scrollToSection} />
    <button class="menu-button" on:click={onClickMenu}>
      <img src="/images/ic_menu.svg" alt="menu" class="menu-icon" />
    </button>
  </div>
</header>

<style>
  header {
    @apply absolute
      w-full
      z-20
      p-10;
  }
  .inner {
    @apply container 
      mx-auto
      h-full
      flex
      items-center
      justify-between;
  }
  .menu-button {
    @apply block;
  }
  .menu-icon {
    @apply h-10;
  }
  @screen desktop {
    header {
      @apply p-5;
    }
    .menu-button {
      @apply hidden;
    }
  }
</style>
