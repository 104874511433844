<script lang="ts">
</script>

<nav>
  <a href="#price" on:click|preventDefault>料金プラン</a>
  <a href="#coach" on:click|preventDefault>コーチについて</a>
  <a href="#qa" on:click|preventDefault>良くある質問</a>
</nav>

<style>
  nav {
    @apply space-x-4 
      hidden
      mr-4;
  }
  a {
    @apply text-xl 
      text-appBlack;
  }
  @screen desktop {
    nav {
      @apply block;
    }
  }
</style>
