<script>
</script>

<div class="wrapper">
  <img class="clip" src="/images/coach_w.png" alt="concept_image" />
</div>

<style lang="postcss">
  .wrapper {
    @apply absolute
      top-0
      left-1/2
      h-full;
  }
  img {
    width: 260px;
    margin-top: 110px;
    margin-left: 110px;
  }
  .clip {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 40% 100%);
  }
</style>
