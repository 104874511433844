<script lang="ts">
  import clsx from 'clsx'
  import RoundButtonSmall from 'src/elements/RoundButtonSmall.svelte'
  import Body from 'src/theme/text/Body.svelte'
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  export let className = ''
  export let coachId = ''
  export let maintenance = false
</script>

<div class={clsx('coach-card', className)}>
  <slot />
  {#if maintenance}
    <Body
      className="text-red-500 font-bold mt-4"
      text="申し訳ございません。ただいま満員のため受付を中止させていただいております。"
    />
  {:else}
    <RoundButtonSmall text="コーチの詳細" on:click={() => dispatch('clickShowCoach', { coachId })} className="mt-4" />
  {/if}
</div>

<style>
  .coach-card {
    @apply flex flex-col;
  }

  .coach-img {
    @apply w-28
      h-28  mb-4;

    border-radius: 50%;
  }

  @screen laptop {
    .coach-img {
      @apply w-32 
        h-32 mb-8;
    }
  }

  @screen desktop {
    .coach-img {
      @apply w-32 
        h-32 mb-8;
    }
  }
</style>
