<script>
  import Title from 'src/theme/text/Title.svelte'
  import TitleDecoration from '../../TitleDecoration.svelte'
  import DescriptionCard from './DescriptionCard.svelte'
  import UserCard from './UserCard.svelte'
</script>

<div class="section">
  <div class="inner">
    <div class="description">
      <DescriptionCard on:click />
    </div>
    <div class="title">
      <TitleDecoration>
        <Title text="こんな人におすすめしています" className="text-white font-bold" />
      </TitleDecoration>
    </div>
    <div class="users">
      <UserCard
        className="mb-8"
        imageSrc="/images/user-1.svg"
        caption="プログラミングスクールを卒業したけど"
        highlight="まだまだ技術力に自信がない"
      />
      <UserCard
        className="mb-8"
        imageSrc="/images/user-2.svg"
        caption="キャリアアップしたいけど"
        highlight="ひとりだと不安が多い"
      />
      <UserCard
        className="mb-8"
        imageSrc="/images/user-3.svg"
        caption="フリーランスエンジニアを始めたけど"
        highlight="どうやって案件を獲得していけばいいかわからない"
      />
      <UserCard
        className="mb-8"
        imageSrc="/images/user-4.svg"
        caption="ソフトウェアエンジニアに興味があるが"
        highlight="何から始めたらいいかわからない"
      />
    </div>
    <div class="arrow-bottom" />
  </div>
</div>

<style lang="postcss">
  .section {
    @apply relative pb-12;

    background: #404855;
  }

  .inner {
    @apply relative
      h-full
      flex
      flex-col
      items-center
      container 
      mx-auto
      px-5;
  }

  .description {
    @apply max-w-5xl 
      w-full 
      mb-16;

    margin-top: -232px;
  }

  .title {
    @apply mb-16;
  }

  .users {
    @apply flex 
      flex-col 
      justify-around 
      items-center;
  }

  .arrow-bottom {
    @apply w-0 h-0 absolute left-0 right-0 mx-auto bottom-0 -mb-24;

    border-color: #404855 transparent transparent;
    border-style: solid;
    border-width: 64px 100px 0;
  }

  @screen laptop {
    .inner {
      @apply px-24;
    }

    .description {
      @apply mb-16;

      margin-top: -240px;
    }
  }

  @screen desktop {
    .inner {
      @apply px-0;
    }

    .description {
      @apply mb-32;

      margin-top: -160px;
    }

    .users {
      @apply flex-row flex-wrap;
    }
  }
</style>
