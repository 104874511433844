<script lang="ts">
  import clsx from 'clsx'
  import Body from 'src/theme/text/Body.svelte'
  import TitleSmall from 'src/theme/text/TitleSmall.svelte'
  export let no: string
  export let title: string
  export let imgSrc: string
  export let reverse = false
</script>

<div class={clsx('card', reverse && 'card-reverse')}>
  <div class="cover">
    <p class="number">{no}</p>
    <img src={imgSrc} alt="benefit_image" class="image" />
  </div>
  <div class="description">
    <TitleSmall text={title} />
    <slot>
      <Body text="ここにテキストが入ります。" />
    </slot>
  </div>
</div>

<style>
  .card {
    @apply relative w-full
      flex
      flex-col
      items-center
      bg-appBlue-500;
  }

  .cover {
    @apply w-full relative;

    height: 100px;
  }

  .number {
    @apply absolute 
      font-bold 
      text-5xl 
      text-white
      text-opacity-60
      left-4
      top-1/2
      -translate-y-1/2;
  }

  .image {
    @apply object-cover w-full h-full;
  }

  .description {
    @apply px-6 py-8;
  }

  @screen laptop {
    .card {
      @apply flex-row-reverse;
    }

    .card-reverse {
      @apply flex-row;
    }

    .cover {
      @apply w-80 h-80 flex-shrink-0;
    }

    .number {
      @apply text-8xl
        right-4
        top-0
        text-right
        translate-y-0;
    }
  }

  @screen desktop {
    .card {
      @apply flex-row-reverse;
    }

    .card-reverse {
      @apply flex-row;
    }

    .cover {
      @apply w-80 h-80 flex-shrink-0;
    }

    .number {
      @apply text-8xl
        right-4
        top-0
        text-right
        translate-y-0;
    }
  }
</style>
