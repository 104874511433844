<script>
</script>

<div class="bg">
  <img src="/images/service-cover.png" alt="services-cover" />
  <div class="bg-left" />
  <div class="bg-right" />
</div>

<style lang="postcss">
  .bg {
    @apply absolute
      top-0
      left-0
      h-full
      w-full
      -z-20;
  }
  .bg-left {
    @apply absolute left-0 bottom-0 h-20 w-1/2;
    clip-path: polygon(0 0, 0 100%, 100% 100%);
    background-color: #404855;
  }
  .bg-right {
    @apply absolute right-0 bottom-0 h-20 w-1/2;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    background-color: #404855;
  }
  img {
    @apply h-full 
      w-full 
      object-cover;
  }
</style>
