<script lang="ts">
  import clsx from 'clsx'
  export let text: string
  export let className = ''
  export let decoration = false
</script>

<p class={clsx(className, decoration && 'decoration')}>{text}</p>

<style>
  .decoration {
    display: inline;
    background: linear-gradient(transparent 50%, var(--color-app-yellow) 50%);
  }
  p {
    @apply text-xl font-bold;
  }
  @screen desktop {
    p {
      @apply text-3xl;
    }
  }
  @screen laptop {
    p {
      @apply text-3xl;
    }
  }
</style>
