<script lang="ts">
  import RoundButton from 'src/elements/RoundButton.svelte'
  import Body from 'src/theme/text/Body.svelte'
  import Title from 'src/theme/text/Title.svelte'
  import TitleSmall from 'src/theme/text/TitleSmall.svelte'
</script>

<div class="card">
  <div class="title">
    <Title text="エンジニア専門のパーソナルコーチング" className="font-bold text-center" />
  </div>
  <div class="sub-title">
    <TitleSmall
      text={`「TECH FOR YOU」は、ソフトウェアエンジニアに特化したコーチングを提供しています。`}
      className="font-bold text-center"
      textColor="#A78805"
    />
  </div>
  <div class="body-1">
    <Body
      className="text-center"
      text="エンジニアとして飛躍したいあなたへ。目標達成のためのマンツーマンサポートを提供します！"
    />
  </div>
  <div class="body-2">
    <Body
      text="ソフトウェアエンジニアは、専門性が高く、一人で解決するには難しい課題がたくさんあります。だからこそ、信頼できるコーチと一緒に取り組むことで、確実にステップアップしていきましょう。"
      className="text-center"
    />
  </div>

  <div class="button">
    <RoundButton text="無料相談を受けてみる" on:click />
  </div>
</div>

<style lang="postcss">
  .card {
    @apply flex 
      flex-col 
      items-center 
      py-12 
      px-6;

    width: 100%;
    background: white;
  }

  .title {
    @apply mb-8;
  }

  .sub-title {
    @apply mb-8;

    color: #a78805;
  }

  .body-1 {
    @apply mb-8;
  }

  .body-2 {
    @apply mb-8;
  }

  @screen laptop {
    .card {
      @apply py-24 px-16;
    }

    .title {
      @apply mb-16;
    }

    .sub-title {
      @apply mb-16;
    }

    .body-2 {
      @apply mb-16;
    }
  }

  @screen desktop {
    .card {
      @apply py-24 px-32;
    }

    .title {
      @apply mb-24;
    }

    .sub-title {
      @apply mb-24;
    }

    .body-2 {
      @apply mb-24;
    }
  }
</style>
