<script>
  import clsx from 'clsx'
  import BodyLarge from 'src/theme/text/BodyLarge.svelte'

  export let className = ''
</script>

<div class={clsx('card', className)}>
  <div class="wrapper">
    <div><BodyLarge text="【誰かに相談しながらキャリアアップしたい】" /></div>
    <div><BodyLarge text="【勉強をしたい気持ちはあるけど、なかなか継続できない】" /></div>
    <div><BodyLarge text="【資格学習のサポートをしてほしい】" /></div>
    <div><BodyLarge text="【キャリアに漠然とした不安がある】" /></div>
    <div><BodyLarge text="【業務で困っていることがあるけど、誰に相談したらいいかわからない】" /></div>
    <div><BodyLarge text="など、多くの目的でご利用いただけます。" /></div>
  </div>
</div>

<style lang="postcss">
  .card {
    @apply w-full
      border 
      border-black
      pr-1
      pb-1;
  }

  .wrapper {
    @apply px-6 
      py-4 
      border-b
      border-r
      border-black
      space-y-4;
  }
</style>
