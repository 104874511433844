<script lang="ts">
  import RoundButton from 'src/elements/RoundButton.svelte'
  import Body from 'src/theme/text/Body.svelte'
  import TitleSmall from 'src/theme/text/TitleSmall.svelte'
  import PlanCard from './PlanCard.svelte'

  export let coachId = ''
  export let onClickApplyTrial = (coachId: string) => {
    console.log('onClickApplyTrial', coachId)
  }
</script>

<div class="container">
  <div class="profile-container">
    <img class="coach-img" src="/images/coach-6.png" alt="coach" />
    <div class="profile">
      <TitleSmall text="近藤 豊生" className="font-bold" />
      <Body
        text="大学の情報科学科でプログラミングにつまづき二度の留年を経てなんとか卒業したあと、ソフトウェアエンジニアとして、外資系IT企業・スタートアップ・行政機関・研究機関など多様な現場で働いてきました。その他、認定スクラムマスターとして開発組織作りの支援を行ったり、学生エンジニアの育成コミュニティでメンターとして活動したりしています。"
        className="mt-6"
      />
      <br />
      <Body
        text="私自身エンジニアを難しいながらもとてもやりがいのある職業だと感じており、そんな現場で活き活きとしたキャリアを描ける仲間が一人でも多く増えたらいいなという気持ちで、皆さまのご活躍のお手伝いをしています。"
      />

      <Body text="■ コーチングに関して" className="mt-4" />
      <Body
        text="エンジニアとしてのスキルアップの方略やキャリアの描き方は、本当に人それぞれ・十人十色だと思っています。皆さま一人ひとりの得意苦手や経験・状況なども丁寧にお伺いしながら、ご自身の真の目標や目的の達成に向けて、技術的にも心理的にもご一緒に伴走していきます。"
      />

      <Body text="■ 主なスキルセット" className="mt-4" />
      <Body text="✅ フロントエンド開発 (React/Next/React Native, Vue)" />
      <Body text="✅ バックエンド開発 (Node, Django, .NET, Spring)" />
      <Body text="✅ アルゴリズム開発 (Rust, Python)" />
      <Body text="✅ データサイエンス (Python/Jupyter/NumPy)" />
      <Body text="✅ スクラム開発支援 (認定スクラムマスター)" />
      <Body text="✅ クラウドインフラ少し (Azure, AWS)" />

      <Body text="■ コーチングセッション対応可能時間" className="mt-4" />
      <Body text="平日：20:00〜23:00" />
      <Body text="土日祝：応相談" />
      <Body text="※ 無料相談で具体的に調整させていただきます。" />
    </div>
  </div>
  <div class="plan-container">
    <div class="plan-card"><PlanCard plan={{ name: '2回/月コース', price: '8,000円' }} /></div>
    <div class="plan-card"><PlanCard plan={{ name: 'お試し(1回)', price: '4,000円' }} /></div>
  </div>
  <RoundButton text="無料相談する" className="mt-16" on:click={() => onClickApplyTrial(coachId)} minWidth={200} />
</div>

<style>
  .container {
    @apply flex 
      flex-col pb-8;
  }

  .profile-container {
    @apply flex 
      w-full
      flex-col;
  }

  .coach-img {
    @apply w-28
      h-28
      mb-16;

    border-radius: 50%;
  }

  .plan-container {
    @apply mt-8
    flex 
      justify-center
      items-center
      flex-wrap
      gap-4;
  }

  .plan-card {
    @apply w-full;
  }

  @screen tablet {
    .plan-card {
      @apply w-full;
    }
  }

  @screen laptop {
    .coach-img {
      @apply w-40 
        h-40;
    }

    .plan-container {
      @apply justify-center;
    }

    .plan-card {
      @apply w-48;
    }
  }

  @screen desktop {
    .coach-img {
      @apply mr-16;
    }

    .plan-container {
      @apply justify-center gap-32;
    }

    .plan-card {
      @apply w-60;
    }
  }
</style>
