<script lang="ts">
  import clsx from 'clsx'
  import Body from 'src/theme/text/Body.svelte'
  import TitleSmall from 'src/theme/text/TitleSmall.svelte'

  export let className = ''
  export let imgSrc = ''
  export let name = ''
  export let description = ''
  export let discount = false
</script>

<div class={clsx('coach-detail', className)}>
  <img class="coach-img" src={imgSrc} alt="coach" />
  <div class="coach-img-container">
    <TitleSmall text={name} className="font-bold" />
    {#if discount}
      <div class="discount-tip">期間限定の割引中</div>
    {/if}
  </div>
  <Body text={description} className="mt-4" />
</div>

<style>
  .coach-detail {
    @apply flex flex-col;
  }

  .coach-img-container {
    @apply flex items-center;
  }

  .discount-tip {
    @apply text-sm text-white bg-orange-400 font-bold py-1 px-3 ml-4 rounded-full; /* スタイルを更新 */
  }

  .coach-img {
    @apply w-28
      h-28  mb-4;

    border-radius: 50%;
  }

  @screen laptop {
    .coach-img {
      @apply w-32 
        h-32 mb-8;
    }
  }

  @screen desktop {
    .coach-img {
      @apply w-32 
        h-32 mb-8;
    }
  }
</style>
