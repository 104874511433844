<div class="wrapper">
  <div class="bg" />
  <div class="arrow" />
</div>

<style lang="postcss">
  .wrapper {
    @apply relative w-full
      h-20;
  }
  .bg {
    @apply absolute bg-white;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .arrow {
    @apply absolute z-10 bg-appYellow-500;
    clip-path: polygon(100% 0%, 0% 0%, 50% 100%);
    content: '';
    @apply w-full h-full;
  }
</style>
