<script>
  import TitleDecoration from 'src/components/lp/TitleDecoration.svelte'
  import Body from 'src/theme/text/Body.svelte'
  import Title from 'src/theme/text/Title.svelte'

  import PointCard from './PointCard.svelte'
</script>

<div class="section">
  <div class="inner">
    <div class="title">
      <TitleDecoration color="black">
        <Title text="3つのポイント" className="font-bold" />
      </TitleDecoration>
    </div>
    <div class="description">
      <Body className="inline" text="ソフトウェアエンジニアに必要なのは" />
      <Body className="inline" text="正しく学ぶ力" decoration />
      <Body className="inline" text="、" />
      <Body className="inline" text="継続する力" decoration />
      <Body className="inline" text="を身につけることです。" />
      <br />
      <br />
      <Body className="inline" text="Tech For Youでは、「" />
      <Body className="inline" text="目標設定" decoration />
      <Body className="inline" text="」「" />
      <Body className="inline" text="ふりかえり" decoration />
      <Body className="inline" text="」「" />
      <Body className="inline" text="相談" decoration />
      <Body className="inline" text="」の3つを軸にこれらの習得をサポートします。" />
      <br />
      <br />
      <Body
        className="inline"
        text="人によって必要な勉強やペースは違います。コーチと一緒に自分に合った勉強方法で、継続できる習慣を身につけていきましょう!"
      />
    </div>

    <div class="points">
      <PointCard
        class="point"
        iconSrc="/images/goal.svg"
        stepSrc="/images/point-1.svg"
        title="目標設定"
        description="将来のキャリアや目標をコーチと一緒に考えて、具体的な目標を設定します。目標に向けて自分に合った勉強方法、カリキュラムを作成します。"
      />
      <PointCard
        class="point"
        iconSrc="/images/lookback.svg"
        stepSrc="/images/point-2.svg"
        title="ふりかえり"
        description="定期的にコーチと一緒にふりかえりを行い、上手くいかない勉強方法や習慣を見直します。"
      />
      <PointCard
        class="point"
        iconSrc="/images/consultation.svg"
        stepSrc="/images/point-3.svg"
        title="相談"
        description="目標へ向かうために悩んでいること、不安なことをコーチへ相談して一緒に解決します。"
      />
    </div>
  </div>
</div>

<style lang="postcss">
  .section {
    @apply relative 
      -mt-16
      pt-16
      bg-appYellow 
      bg-opacity-50;
  }
  .inner {
    @apply relative
      h-full
      flex
      flex-col
      items-center
      container 
      mx-auto
      px-5
      py-8;
  }
  .description {
    @apply mt-16 px-4;
  }
  .points {
    @apply flex 
      mt-16
      flex-col;
  }
  .points > :global(.point) {
    @apply mb-11;
  }
  @screen laptop {
    .inner {
      @apply px-16 py-16;
    }
    .description {
      @apply px-0;
    }
    .points {
      @apply flex-row;
    }
    .points > :global(.point):not(:first-child) {
      @apply ml-6;
    }
  }
  @screen desktop {
    .inner {
      @apply px-32 py-24;
    }
    .points {
      @apply flex-row;
    }
    .points > :global(.point):not(:first-child) {
      @apply ml-6;
    }
  }
</style>
