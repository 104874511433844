<script lang="ts">
  import clsx from 'clsx'

  export let title: string
</script>

<div class={clsx('card')}>
  <div class="content">
    <p class={clsx('text-title', 'mb-8')}>{title}</p>
    <slot />
  </div>
</div>

<style>
  .card {
    @apply relative 
      w-full
      h-full
      flex
      flex-col
      items-center 
      bg-white;
  }
  .content {
    @apply px-8
      py-12;
  }
  .text-title {
    @apply text-xl
      text-center;
  }
  @screen laptop {
    .text-title {
      @apply text-2xl;
    }
  }
  @screen desktop {
    .text-title {
      @apply text-2xl;
    }
  }
</style>
