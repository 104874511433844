<script>
  import BackgroundMask from './BackgroundMask.svelte'
  import BackgroundMaskPC from './BackgroundMaskPC.svelte'
  import BackgroundMaskTablet from './BackgroundMaskTablet.svelte'
</script>

<div class="bg" />
<BackgroundMaskPC />
<BackgroundMaskTablet />
<BackgroundMask />

<style lang="postcss">
  .bg {
    @apply absolute
      top-0
      left-0
      h-full
      w-full
      -z-20;
    background: linear-gradient(85.78deg, #7ce6d7 -2.18%, #f7dc69 105.33%);
  }
</style>
