<script lang="ts">
  import clsx from 'clsx'
  export let text: string
  export let className = ''
  export let decoration = false
</script>

<p class={clsx(className, decoration && 'decoration')}>{text}</p>

<style>
  p {
    @apply text-4xl 
      font-bold 
      inline-block;
  }

  .decoration {
    display: inline;
    background: linear-gradient(transparent 50%, rgb(145 198 190 / 30%) 50%);
  }

  @screen desktop {
    p {
      @apply text-7xl;
    }
  }

  @screen laptop {
    p {
      @apply text-7xl;
    }
  }
</style>
