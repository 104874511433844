<script>
  import TitleDecoration from 'src/components/lp/TitleDecoration.svelte'
  import Title from 'src/theme/text/Title.svelte'
  import FlowCard from './FlowCard.svelte'
</script>

<div class="section">
  <div class="inner">
    <div class="title">
      <TitleDecoration color="black">
        <Title text="ご利用までの流れ" className="font-bold" />
      </TitleDecoration>
    </div>
    <div class="steps">
      <div class="line-h" />
      <div class="step">
        <FlowCard
          step="1"
          title="無料相談"
          body="サービスへの疑問や質問に無料でお答えします。達成したい目標や実現したいこと、希望するサポートなどを気軽に相談ください。(オンライン)"
        />
      </div>
      <div class="step">
        <FlowCard
          step="2"
          title="申し込み"
          body="面談後、サービスを利用を希望する場合は、WEBから申し込みを行います。"
        />
      </div>
      <div class="step">
        <FlowCard
          step="3"
          title="サービス開始"
          body="申し込み後すぐに専用のチャットルームへ招待します。申し込み日からすぐにチャット相談は可能です。定期面談のスケジュールは、都合の良い日程で調整します。"
        />
      </div>
    </div>
  </div>
</div>

<style lang="postcss">
  .section {
    @apply relative 
      pt-16
      bg-appYellow-500;
  }
  .inner {
    @apply relative
      container
      h-full
      flex
      flex-col
      items-center
      mx-auto;
  }
  .steps {
    @apply relative w-full
      flex 
      flex-col
      px-16
      my-8
      space-y-6;
  }

  @screen laptop {
    .steps {
      @apply flex-row
        space-y-0
        my-16;
    }
    .step {
      @apply basis-1/3;
    }
    .line-h {
      @apply absolute 
        border-t-2 
        border-black;
      top: 60px;
      right: 212px;
      left: 212px;
    }
  }
</style>
