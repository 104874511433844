<script lang="ts">
  import RoundButton from 'src/elements/RoundButton.svelte'
  import Body from 'src/theme/text/Body.svelte'
  import TitleSmall from 'src/theme/text/TitleSmall.svelte'
  import PlanCard from './PlanCard.svelte'

  export let coachId = ''
  export let onClickApplyTrial = (coachId: string) => {
    console.log('onClickApplyTrial', coachId)
  }
</script>

<div class="container">
  <div class="profile-container">
    <img class="coach-img" src="/images/coach-4.jpeg" alt="coach" />
    <div class="profile">
      <TitleSmall text={'田中 啓太'} className="font-bold" />
      <Body
        text="システムエンジニアとして3年目を迎えました。現在は、自社のパッケージ製品の開発チームにて日々、業務に取り組んでいます。大学では情報系を専攻し、そのまま新卒でIT業界に飛び込みました。まだ経験は浅いかもしれませんが、その分フレッシュな視点でお話ができるのが私の強みです。どうぞ壁を感じず、気軽に相談していただければ幸いです。一緒に皆さんの人生を前に進めるお手伝いができることを楽しみにしています。"
        className="mt-6"
      />

      <Body text="■ 主なスキルセット" className="mt-4" />
      <Body text="✅ 組み込み開発（C）" />
      <Body text="✅ オンプレミスサーバー保守・運用" />
      <Body text="✅ ウォーターフォール開発" />
      <Body text="✅ クラウドでのインフラ構築（AWS、OCI）" />
      <Body text="✅ プロジェクトリーダー" />

      <Body text="■ こんな方におすすめ" className="mt-4" />
      <Body text="・IT業界に関してまずはざっくばらんに話してみたい方" />
      <Body text="・コーチングに興味があるけど、怖くて踏み出せない" />
      <Body text="・若いコーチと話して刺激を受けたい" />

      <Body text="■ コーチングセッション対応可能時間" className="mt-4" />
      <Body text="平日：20:00〜23:00" />
      <Body text="土日祝：7:30〜12:00 13:00〜23:00" />
      <Body text="※ 詳細な時間は、無料相談にて調整可能です。" />
    </div>
  </div>
  <div class="plan-container">
    <div class="plan-card"><PlanCard plan={{ name: '2回/月コース', price: '4,000円' }} /></div>
    <div class="plan-card"><PlanCard plan={{ name: '1回/月コース', price: '2,000円' }} /></div>
  </div>
  <RoundButton text="無料相談する" className="mt-16" on:click={() => onClickApplyTrial(coachId)} minWidth={200} />
</div>

<style>
  .container {
    @apply flex 
      flex-col pb-8;
  }

  .profile-container {
    @apply flex 
      w-full
      flex-col;
  }

  .coach-img {
    @apply w-28
      h-28
      mb-16;

    border-radius: 50%;
  }

  .plan-container {
    @apply mt-8
    flex 
      justify-center
      items-center
      flex-wrap
      gap-4;
  }

  .plan-card {
    @apply w-full;
  }

  @screen tablet {
    .plan-card {
      @apply w-full;
    }
  }

  @screen laptop {
    .coach-img {
      @apply w-40 
        h-40;
    }

    .plan-container {
      @apply justify-center;
    }

    .plan-card {
      @apply w-48;
    }
  }

  @screen desktop {
    .coach-img {
      @apply mr-16;
    }

    .plan-container {
      @apply justify-center gap-32;
    }

    .plan-card {
      @apply w-60;
    }
  }
</style>
