<script lang="ts">
  import RoundButton from 'src/elements/RoundButton.svelte'
  import Body from 'src/theme/text/Body.svelte'
  import TitleSmall from 'src/theme/text/TitleSmall.svelte'
  import PlanCard from './PlanCard.svelte'

  export let coachId = ''
  export let onClickApplyTrial = (coachId: string) => {
    console.log('onClickApplyTrial', coachId)
  }
</script>

<div class="container">
  <div class="profile-container">
    <img class="coach-img" src="/images/coach-3.jpeg" alt="coach" />
    <div class="profile">
      <TitleSmall text={'黒木 "リック" 隆一郎'} className="font-bold" />
      <Body
        text="大学卒業後、技術系社員として現在の会社に就職し、長い間ソフトウエアエンジニアとして商品開発実務に携わっていました。
その様な経験を経て45歳で管理職となった瞬間に、仕事への向き合い方を大きく変える事になりました。それまで技術者としてスキルを高めて来た自分が、今度はマネジメント業務に取り組む事になりました。それから約15年、SCRUM導入による自走するチーム、組織開発による活き活きとした組織の実現に試行錯誤をしながら、ここまで一定の成果を出して来る事が出来ました。
この様なエンジニアとしての経験、技術系管理職としての経験を活かして、エンジニアの皆さんが目指している将来の実現に向けてサポートしていきたいと思います。"
        className="mt-6"
      />

      <Body text="■ 今までのキャリア" className="mt-4" />
      <Body text="▷ エンジニアとしてデジタル信号処理、組込み系ソフトウエア経験" />
      <Body text="▷ 管理職として、商品開発、技術開発チームのチームマネジメント、人材育成" />
      <Body text="▷ 2019年頃社内組織開発活動を通してコーチングと出会い、1on1の取り組みを開始" />

      <Body text="■ 主なスキルセット" className="mt-4" />
      <Body text="✅ 銀座コーチングスクール認定コーチ" />
      <Body text="✅ Scrum inc. Registered Scrum Master" />
      <Body text="✅ Scrum inc. Registered Product Owner" />
      <Body text="✅ デジタル信号処理技術" />
      <Body text="✅ 組み込みソフトウエア開発" />

      <Body text="■ コーチングセッション対応可能時間" className="mt-4" />
      <Body text="平日：20時から22時。" />
      <Body text="土日祝：応談可。" />
      <Body text="※ 無料相談で具体的に調整させていただきます。" />
    </div>
  </div>
  <div class="plan-container">
    <div class="plan-card"><PlanCard plan={{ name: '4回/月コース', price: '16,000円' }} /></div>
    <div class="plan-card"><PlanCard plan={{ name: '2回/月コース', price: '10,000円' }} /></div>
    <div class="plan-card"><PlanCard plan={{ name: 'お試し(1回)', price: '5,000円' }} /></div>
  </div>
  <RoundButton text="無料相談する" className="mt-16" on:click={() => onClickApplyTrial(coachId)} minWidth={200} />
</div>

<style>
  .container {
    @apply flex 
      flex-col pb-8;
  }

  .profile-container {
    @apply flex 
      w-full
      flex-col;
  }

  .coach-img {
    @apply w-28
      h-28
      mb-16;

    border-radius: 50%;
  }

  .plan-container {
    @apply mt-8
    flex 
      justify-center
      items-center
      flex-wrap
      gap-4;
  }

  .plan-card {
    @apply w-full;
  }

  @screen tablet {
    .plan-card {
      @apply w-full;
    }
  }

  @screen laptop {
    .coach-img {
      @apply w-40 
        h-40;
    }

    .plan-container {
      @apply justify-center;
    }

    .plan-card {
      @apply w-48;
    }
  }

  @screen desktop {
    .coach-img {
      @apply mr-16;
    }

    .plan-container {
      @apply justify-between;
    }

    .plan-card {
      @apply w-60;
    }
  }
</style>
