<script lang="ts">
  import clsx from 'clsx'
  import Display from 'src/theme/text/Display.svelte'
</script>

<div class={clsx('card', $$props.class)}>
  <Display text={$$props.price} className="text-white" />
  <div class="description">
    <p class="text text-base laptop:text-3xl font-bold">月額</p>
    <p class="text text-sm laptop:text-2xl mt-1">（税込）</p>
  </div>
</div>
<p class="text text-sm laptop:text-2xl mt-1">※月{$$props.session}回のセッション</p>

<style lang="postcss">
  .card {
    @apply flex justify-center;
  }

  .description {
    @apply flex flex-col items-center justify-center;
  }

  .text {
    @apply text-white;
  }
</style>
