<script>
  import Body from 'src/theme/text/Body.svelte'
  import Title from 'src/theme/text/Title.svelte'
  import TitleDecoration from '../../TitleDecoration.svelte'
  import Background from './Background.svelte'
  import ServicesCard from './ServicesCard.svelte'
</script>

<div class="section">
  <Background />
  <div class="inner">
    <div class="title">
      <TitleDecoration>
        <Title text="サービス内容" className="font-bold text-white" />
      </TitleDecoration>
    </div>
    <div class="cards">
      <div class="card">
        <ServicesCard title="# オンラインチャットルームでの相談">
          <Body
            text="専用のチャットルームへ招待します。困ったことや日々の進捗はチャットでいつでも相談可能です。自分ひとりでは解決できない技術的な質問も可能です。"
          />
        </ServicesCard>
      </div>
      <div class="card">
        <ServicesCard title="# 月に最大4回のオンライン定期面談">
          <Body
            text="週に1回を目安(頻度は応相談)にコーチとオンラインで直接相談できます。目標への進捗、日々の不安に思っていること、相談ごとをコーチと一緒に解決します。"
          />
        </ServicesCard>
      </div>
      <div class="card">
        <ServicesCard title="# 困ったことをマンツーマンで一緒に解決">
          <Body text="その他、勉強内容について、キャリアについて、など困ったことは随時相談可能です。" />
        </ServicesCard>
      </div>
    </div>
  </div>
</div>

<style>
  .section {
    @apply relative 
      pt-16
      pb-16;
  }
  .inner {
    @apply relative
      h-full
      flex
      flex-col
      items-center
      container 
      mx-auto
      px-5
      py-8;
  }
  .cards {
    @apply mt-16
      flex
      flex-col
      justify-center
      space-x-0;
  }
  .card {
    @apply w-full
      mb-6;
  }
  @screen laptop {
    .cards {
      @apply flex-row
        flex-wrap
        space-x-6;
    }
    .card {
      @apply w-80
        h-96;
    }
  }
  @screen desktop {
    .cards {
      @apply flex-row
        flex-wrap
        space-x-6;
    }
    .card {
      @apply w-80;
    }
  }
</style>
