<script lang="ts">
  import clsx from 'clsx'
  import Body from 'src/theme/text/Body.svelte'
  import TitleSmall from 'src/theme/text/TitleSmall.svelte'
  export let step: string
  export let title: string
  export let body: string
</script>

<div class={clsx('card')}>
  <div class="step">
    <div class="line-v" />
    <p class="label-step">STEP</p>
    <div class="step-icon">
      <p class="step-num">{step}</p>
    </div>
  </div>
  <div class="content">
    <div class="title"><TitleSmall text={title} className="font-bold" /></div>
    <div class="body"><Body text={body} /></div>
  </div>
</div>

<style>
  .card {
    @apply relative w-full
      flex
      flex-row;
  }
  .step {
    @apply relative;
  }
  .line-v {
    @apply absolute 
      border-l-2 
      w-0
      mx-auto
      left-0
      right-0
      top-1/3
      bottom-0
      border-black;
  }
  .step-icon {
    @apply rounded-full 
      w-9 
      h-9
      flex
      justify-center
      items-center
      mt-1
      z-10;
    background-color: black;
  }
  .step-num {
    @apply text-white 
      text-xl
      z-10;
  }
  .label-step {
    @apply text-sm 
      text-center
      font-bold 
      z-10;
  }
  .content {
    @apply pt-6 px-5;
  }
  .body {
    @apply mt-3;
  }
  @screen laptop {
    .card {
      @apply flex-col
        items-center;
    }
    .line-v {
      @apply hidden;
    }
    .step-icon {
      @apply w-16 
        h-16;
    }
    .step-num {
      @apply text-4xl;
    }
    .label-step {
      @apply text-base;
    }
    .title {
      @apply text-center;
    }
    .body {
      @apply mt-6;
    }
  }
</style>
