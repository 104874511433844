<script>
</script>

<div class="clip wrapper">
  <img src="/images/coach_w.png" alt="concept_image" />
</div>

<style lang="postcss">
  .wrapper {
    @apply absolute
      top-0
      left-1/2
      h-full
      w-1/2;
  }
  img {
    width: 600px;
    padding-left: 90px;
    margin-top: 120px;
  }
  .clip {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 60% 100%);
  }
</style>
