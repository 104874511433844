<script lang="ts">
  import clsx from 'clsx'
  export let text: string
  export let className = ''
</script>

<p class={clsx(className)}>{text}</p>

<style>
  p {
    @apply text-lg;
  }
  @screen laptop {
    p {
      @apply text-xl;
    }
  }
  @screen desktop {
    p {
      @apply text-2xl;
    }
  }
</style>
