<script lang="ts">
  import RoundButton from 'src/elements/RoundButton.svelte'
  import Body from 'src/theme/text/Body.svelte'
  import TitleSmall from 'src/theme/text/TitleSmall.svelte'
  import PlanCard from './PlanCard.svelte'

  export let coachId = ''
  export let onClickApplyTrial = (coachId: string) => {
    console.log('onClickApplyTrial', coachId)
  }
</script>

<div class="container">
  <div class="profile-container">
    <img class="coach-img" src="/images/coach-1.jpeg" alt="coach" />
    <div class="profile">
      <TitleSmall text="諸岡 明" className="font-bold" />
      <Body
        text="ソフトウェアエンジニア歴 15年。現在はフリーランスのエンジニアとして活動。Webやオープン系のソフトウェア開発をメインに多くのチームや企業を見てきた経験をもと皆さんをサポートします。"
        className="mt-6"
      />

      <Body text="■ 今までのエンジニアキャリア" className="mt-4" />
      <Body text="▷ 中規模Sier (iOSのプログラマとプロジェクトリーダー)" />
      <Body text="▷ 面白法人カヤック (Androidエンジニア、プロジェクトマネージャー)" />
      <Body text="▷ スタートアップのテックリード" />
      <Body text="▷ フリーランスエンジニア" />

      <Body text="■ 講師業務経験" className="mt-4" />
      <Body text="▷ IT企業の新入社員研修の講師経験" />
      <Body text="▷ 主婦向けのプログラミング研修講師経験" />
      <Body text="▷ ジョブチェンジ希望者向けのメンター" />

      <Body text="■ 主なスキルセット" className="mt-4" />
      <Body text="✅ モバイル(iOS, Android)アプリ開発" />
      <Body text="✅ Webアプリ開発(React, etc)" />
      <Body text="✅ サーバアプリ開発(RubyOnRails, Node, etc)" />
      <Body text="✅ スクラム開発支援" />
      <Body text="✅ プロジェクトマネージャー(小規模)" />
    </div>
  </div>
  <div class="plan-container">
    <div class="plan-card"><PlanCard plan={{ name: '4回/月コース', price: '16,000円' }} /></div>
    <div class="plan-card"><PlanCard plan={{ name: '2回/月コース', price: '10,000円' }} /></div>
    <div class="plan-card"><PlanCard plan={{ name: 'お試し(1回)', price: '5,000円' }} /></div>
  </div>
  <RoundButton text="無料相談する" className="mt-16" on:click={() => onClickApplyTrial(coachId)} minWidth={200} />
</div>

<style>
  .container {
    @apply flex 
      flex-col pb-8;
  }

  .profile-container {
    @apply flex 
      w-full
      flex-col;
  }

  .coach-img {
    @apply w-28
      h-28
      mb-16;

    border-radius: 50%;
  }

  .plan-container {
    @apply mt-8
    flex 
      justify-center
      items-center
      flex-wrap
      gap-4;
  }

  .plan-card {
    @apply w-full;
  }

  @screen tablet {
    .plan-card {
      @apply w-full;
    }
  }

  @screen laptop {
    .coach-img {
      @apply w-40 
        h-40;
    }

    .plan-container {
      @apply justify-center;
    }

    .plan-card {
      @apply w-48;
    }
  }

  @screen desktop {
    .coach-img {
      @apply mr-16;
    }

    .plan-container {
      @apply justify-between;
    }

    .plan-card {
      @apply w-60;
    }
  }
</style>
