<script lang="ts">
  import clsx from 'clsx'
  import Body from 'src/theme/text/Body.svelte'
  import { slide } from 'svelte/transition'
  export let question: string
  export let answer: string
  let isOpen = false
  let iconSrc = '/images/ic-plus.svg'
  const toggleIsOpen = () => {
    isOpen = !isOpen
    iconSrc = isOpen ? '/images/ic-minus.svg' : '/images/ic-plus.svg'
  }
</script>

<div class={clsx('card', $$props.class)}>
  <div class="question">
    <div class="body">
      <Body text="Q.  " className="inline" /><Body text={`${question}`} className="inline" />
    </div>
    <button class="icon" on:click={toggleIsOpen}>
      <img src={iconSrc} alt="button" />
    </button>
  </div>
  {#if isOpen}
    <div class="answer" transition:slide={{ duration: 300 }}>
      <Body text={`${answer}`} />
    </div>
  {/if}
</div>

<style lang="postcss">
  .card {
    @apply flex 
      flex-col
      justify-center
      w-full;
  }

  .question {
    @apply w-full
      py-8 
      px-8
      bg-white
      flex
      justify-between
      items-center;
  }

  .icon {
    @apply ml-2 
      w-4
      p-2 
      box-content;
  }

  .answer {
    @apply w-full
      py-8
      pl-8
      pr-16;

    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  }

  @screen laptop {
    .question {
      @apply px-16;
    }

    .icon {
      @apply w-8;
    }

    .answer {
      @apply pl-16 pr-32;
    }
  }
</style>
