<script lang="ts">
  import { slide } from 'svelte/transition'
  export let open = false
  export let onClickClose = () => {
    // Nothing to do
  }
</script>

{#if open}
  <nav transition:slide={{ duration: 300 }}>
    <div class="menu">
      <a href="#price" on:click|preventDefault>料金プラン</a>
      <a href="#coach" on:click|preventDefault>コーチについて</a>
      <a href="#qa" on:click|preventDefault>良くある質問</a>
    </div>
    <button on:click={onClickClose} class="close-button">
      <img src="/images/close.svg" alt="close" />
    </button>
  </nav>
{/if}

<style>
  .close-button {
    @apply absolute
      p-4
      right-0
      top-0;
  }
  img {
    @apply h-8;
  }
  nav {
    @apply absolute
      p-4
      w-full
      items-center
      left-0
      z-20
      top-0
      bg-accent1;
  }
  .menu {
    @apply flex 
      flex-col
      items-center
      space-y-4;
  }
  a {
    @apply text-sm 
      font-bold 
      text-appBlack;
  }
</style>
