<script lang="ts">
  import TitleDecoration from 'src/components/lp/TitleDecoration.svelte'
  import RoundButton from 'src/elements/RoundButton.svelte'
  import Title from 'src/theme/text/Title.svelte'
  import CoachCard from './CoachCard.svelte'
  import CoachSummary from './CoachSummary.svelte'
</script>

<div id="coach" class="section">
  <div class="inner">
    <div>
      <RoundButton text="無料相談を受けてみる" on:click />
    </div>
    <div class="title">
      <TitleDecoration color="black">
        <Title text="経験豊富なコーチ陣がサポートします" className="font-bold" />
      </TitleDecoration>
    </div>
    <div class="content">
      <div class="card">
        <CoachCard coachId="6" on:clickShowCoach>
          <CoachSummary
            imgSrc="/images/coach-6.png"
            name="近藤 豊生"
            discount={true}
            description="大学の情報科学科でプログラミングにつまづき二度の留年を経てなんとか卒業したあと、ソフトウェアエンジニアとして、外資系IT企業・スタートアップ・行政機関・研究機関など多様な現場で働いてきました。その他、認定スクラムマスターとして開発組織作りの支援を行ったり、学生エンジニアの育成コミュニティでメンターとして活動したりしています。私自身エンジニアを難しいながらもとてもやりがいのある職業だと感じており、そんな現場で活き活きとしたキャリアを描ける仲間が一人でも多く増えたらいいなという気持ちで、皆さまのご活躍のお手伝いをしています。"
          />
        </CoachCard>
      </div>

      <div class="card">
        <CoachCard coachId="4" on:clickShowCoach>
          <CoachSummary
            imgSrc="/images/coach-4.jpeg"
            name="田中 啓太"
            discount={true}
            description="システムエンジニアとして3年目を迎えました。現在は、自社のパッケージ製品の開発チームにて日々、業務に取り組んでいます。大学では情報系を専攻し、そのまま新卒でIT業界に飛び込みました。まだ経験は浅いかもしれませんが、その分フレッシュな視点でお話ができるのが私の強みです。どうぞ壁を感じず、気軽に相談していただければ幸いです。一緒に皆さんの人生を前に進めるお手伝いができることを楽しみにしています。"
          />
        </CoachCard>
      </div>

      <div class="card">
        <CoachCard coachId="1" on:clickShowCoach>
          <CoachSummary
            imgSrc="/images/coach-1.jpeg"
            name="諸岡 明"
            description="ソフトウェアエンジニア歴 15年。現在はフリーランスのエンジニアとして活動。Webやオープン系のソフトウェア開発をメインに多くのチームや企業を見てきた経験をもと皆さんをサポートします。"
          />
        </CoachCard>
      </div>

      <div class="card">
        <CoachCard coachId="3" on:clickShowCoach>
          <CoachSummary
            imgSrc="/images/coach-3.jpeg"
            name={'黒木 "リック" 隆一郎'}
            description="大学卒業後、技術系社員として現在の会社に就職し、長い間ソフトウエアエンジニアとして商品開発実務に携わっていました。
その様な経験を経て45歳で管理職となった瞬間に、仕事への向き合い方を大きく変える事になりました。それまで技術者としてスキルを高めて来た自分が、今度はマネジメント業務に取り組む事になりました。それから約15年、SCRUM導入による自走するチーム、組織開発による活き活きとした組織の実現に試行錯誤をしながら、ここまで一定の成果を出して来る事が出来ました。
この様なエンジニアとしての経験、技術系管理職としての経験を活かして、エンジニアの皆さんが目指している将来の実現に向けてサポートしていきたいと思います。"
          />
        </CoachCard>
      </div>

      <div class="card">
        <CoachCard coachId="2" on:clickShowCoach maintenance={true}>
          <CoachSummary
            imgSrc="/images/coach-2.jpeg"
            name="下地 眞琴"
            discount={false}
            description="システムエンジニア歴 7年目です。現在は、ユーザー系のSierでKintoneを扱ったアプリ開発を担うチームのリーダーをしています。大学では機械工学というITとはあまり関係のない学科を専攻していましたが、IT会社に就職しました。その為、IT未経験から転職する方などの気持ちは理解できると思います。また、派遣社員の面談なども行っていますので、面接の際に重要なポイントなどもお話できます。"
          />
        </CoachCard>
      </div>

      <!-- <div class="card">
        <CoachCard coachId="5" on:clickShowCoach>
          <CoachSummary
            imgSrc="/images/coach-5.jpeg"
            name="藤原 隆幸"
            discount={true}
            description="東京在住のITエンジニアです。これまで25年以上にわたり、さまざまなIT分野に携わってきました。現在はクラウド技術を中心に活動しています。コーチを始めたきっかけは、エンジニアとして成長を目指す方々を支援し、IT業界で挑戦する楽しさを伝えたいという思いからです。そのため、エンジニアの成長を全力でサポートするITコーチングを提供したいと考えています。"
          />
        </CoachCard>
      </div> -->

      <div class="card"></div>
    </div>
  </div>
</div>

<style lang="postcss">
  .section {
    @apply relative 
      py-8;
  }

  .inner {
    @apply relative
      container
      h-full
      flex
      flex-col
      items-center
      mx-auto
      px-5
      py-8;
  }

  .title {
    @apply pt-16;
  }

  .content {
    @apply flex 
      flex-wrap
      items-start
      justify-around
      mt-16
      gap-y-8;
  }

  @screen laptop {
    .inner {
      @apply px-16 py-16;
    }

    .content {
      @apply flex gap-y-16;
    }

    .card {
      @apply w-full;
    }
  }

  @screen desktop {
    .content {
      @apply gap-x-8 gap-y-8;
    }

    .card {
      @apply w-[440px];
    }
  }
</style>
