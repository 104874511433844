<script lang="ts">
  import clsx from 'clsx'

  export let text = 'Undefined title'
  export let className = ''
  export let width = 220
  export let colorClass = 'bg-appBlue-500'
  export let disabled = false
</script>

<button {disabled} style="width: {width}px;" class={clsx(className, colorClass)} on:click>
  <p>
    {text}
  </p>
</button>

<style>
  button {
    @apply rounded-full
      py-3
      px-5;
  }

  button:disabled {
    @apply opacity-50
      cursor-not-allowed;
  }

  p {
    @apply text-base
      inline-block;
  }

  @screen laptop {
    button {
      @apply py-3
        px-5;
    }

    p {
      @apply text-base;
    }
  }

  @screen desktop {
    button {
      @apply py-3
        px-5;
    }

    p {
      @apply text-base;
    }
  }
</style>
