<script lang="ts">
  import Footer from 'src/components/Footer.svelte'
  import {
    setCurrentScreen,
    clickEvent,
    createApplyTrialEvent,
    createShowCoachEvent,
  } from 'src/components/analytics/Analytics.svelte'
  import EntryPopup from 'src/components/entry/EntryPopup.svelte'
  import Header from 'src/components/lp/header/Header.svelte'
  import SectionArrowBlack from 'src/components/lp/sections/arrow/SectionArrowBlack.svelte'
  import SectionArrowYellow from 'src/components/lp/sections/arrow/SectionArrowYellow.svelte'
  import SectionBenefits from 'src/components/lp/sections/benefits/SectionBenefits.svelte'
  import SectionBottom from 'src/components/lp/sections/bottom/SectionBottom.svelte'
  import SectionCoach from 'src/components/lp/sections/coach/SectionCoach.svelte'
  import SectionFirstView from 'src/components/lp/sections/firstView/SectionFirstView.svelte'
  import SectionFlow from 'src/components/lp/sections/flow/SectionFlow.svelte'
  import SectionPoints from 'src/components/lp/sections/points/SectionPoints.svelte'
  import SectionPrice from 'src/components/lp/sections/price/SectionPrice.svelte'
  import SectionQA from 'src/components/lp/sections/qa/SectionQA.svelte'
  import SectionRecommendation from 'src/components/lp/sections/recommendation/SectionRecommendation.svelte'
  import SectionServices from 'src/components/lp/sections/services/SectionServices.svelte'
  import SectionThirdView from 'src/components/lp/sections/thirdView/SectionThirdView.svelte'
  import KurokiPopup from 'src/components/profile/KurokiPopup.svelte'
  import MorookaPopup from 'src/components/profile/MorookaPopup.svelte'
  import ShimojiPopup from 'src/components/profile/ShimojiPopup.svelte'
  import TanakaPopup from 'src/components/profile/TanakaPopup.svelte'
  import { DefaultContextKey } from 'src/elements/modal/Constant.svelte'
  import { getContext } from 'svelte'

  setCurrentScreen('LP')

  const { open, close } = getContext(DefaultContextKey)
  function onClickShowCoach(e: CustomEvent<any>) {
    const coachId = e.detail.coachId
    console.log('onClickShowCoach', coachId)
    clickEvent(createShowCoachEvent('section_supporter'))

    switch (coachId) {
      case '1':
        open(MorookaPopup, {
          onClickApplyTrial,
        })
        break
      case '2':
        open(ShimojiPopup, {
          onClickApplyTrial,
        })
        break
      case '3':
        open(KurokiPopup, {
          onClickApplyTrial,
        })
        break
      case '4':
        open(TanakaPopup, {
          onClickApplyTrial,
        })
        break
    }
  }

  const onClickApplyTrial = () => {
    close()
    open(EntryPopup)
  }
</script>

<Header />
<SectionFirstView
  on:click={() => {
    clickEvent(createApplyTrialEvent('cta'))
    onClickApplyTrial()
  }}
/>
<SectionRecommendation
  on:click={() => {
    clickEvent(createApplyTrialEvent('top'))
    onClickApplyTrial()
  }}
/>
<SectionThirdView />
<SectionPoints />
<SectionServices />
<SectionCoach
  on:clickShowCoach={onClickShowCoach}
  on:click={() => {
    clickEvent(createApplyTrialEvent('middle'))
    onClickApplyTrial()
  }}
/>
<SectionPrice />
<SectionArrowBlack />
<SectionFlow />
<SectionArrowYellow />
<SectionBenefits />
<SectionQA />
<SectionBottom
  on:click={() => {
    clickEvent(createApplyTrialEvent('bottom'))
    onClickApplyTrial()
  }}
/>
<Footer />
