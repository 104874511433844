<script>
  import ConceptImagePC from './ConceptImagePC.svelte'
</script>

<div class="content">
  <div class="bg-mask-left" />
  <ConceptImagePC />
  <div class="bg-mask-right clip" />
</div>

<style lang="postcss">
  .content {
    @apply hidden;
  }
  .bg-mask-left {
    @apply absolute
      top-0
      left-0
      -z-10      
      h-full
      w-1/2
      bg-lpMask;
  }
  .bg-mask-right {
    @apply absolute
      top-0
      left-1/2
      -z-10      
      w-1/2
      h-full
      bg-lpMask;
  }
  .clip {
    clip-path: polygon(0 0, 0% 100%, 60% 100%);
  }
  @screen desktop {
    .content {
      @apply block;
    }
  }
</style>
